import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { MdFormatListBulletedAdd } from "react-icons/md";
import { PageSectionLevelOperations } from "../page-section/PageSection";
import { DEFAULT_VIDEO_FORM_MODEL } from "../../Constants";

const AddItemOptions = (props) => {
  const getNewFormFieldId = () => {
    //console(props);
    let newFieldEditorId = props.data.section.fields.length + 1;
    //console(newFieldEditorId);
    return newFieldEditorId;
  };

  const addFormField = (fieldDict) => {
    props.operation(PageSectionLevelOperations.AddField, fieldDict);
  };

  const addTextField = () => {
    addFormField({
      editor_id: getNewFormFieldId(),
      id: "",
      type: "text",
      view_label: "",
      capture_label: "",
      form_model: "",
    });
  };

  const addTextareaField = () => {
    addFormField({
      editor_id: getNewFormFieldId(),
      id: "",
      type: "textarea",
      view_label: "",
      capture_label: "",
      form_model: "",
    });
  };

  const addDateField = () => {
    addFormField({
      editor_id: getNewFormFieldId(),
      id: "",
      type: "date",
      view_label: "",
      capture_label: "",
      form_model: "",
    });
  };

  const addEmailField = () => {
    addFormField({
      editor_id: getNewFormFieldId(),
      id: "",
      type: "email",
      view_label: "",
      capture_label: "",
      form_model: "",
    });
  };

  const addImageField = () => {
    addFormField({
      editor_id: getNewFormFieldId(),
      id: "",
      type: "image",
      view_label: "",
      capture_label: "",
      form_model: {
        imageCapture: {
          max: 1,
        }
      },
    });
  };

  const addVideoField = () => {
    addFormField({
      editor_id: getNewFormFieldId(),
      id: "",
      type: "video",
      view_label: "",
      capture_label: "",
      form_model: {
        videoCapture:{...DEFAULT_VIDEO_FORM_MODEL},
      },
    });
  };

  const addCheckboxField = () => {
    addFormField({
      editor_id: getNewFormFieldId(),
      id: "checkbox_field_id_" + getNewFormFieldId(),
      type: "checkbox",
      view_label: "",
      capture_label: "",
      form_model: "",
    });
  };

  const addQuantityField = () => {
    addFormField({
      editor_id: getNewFormFieldId(),
      id: "",
      type: "quantity",
      view_label: "",
      capture_label: "",
      form_model: "",
    });
  };

  const addSelectField = () => {
    addFormField({
      editor_id: getNewFormFieldId(),
      id: "",
      type: "select",
      view_label: "",
      capture_label: "",
      options: [],
      form_model: "",
    });
  };

  const addOptionField = () => {
    addFormField({
      editor_id: getNewFormFieldId(),
      id: "",
      type: "options",
      view_label: "",
      capture_label: "",
      options: [],
      form_model: "",
    });
  };

  return (
    <Dropdown>
      <Dropdown.Toggle id="dropdown-basic">
        <MdFormatListBulletedAdd />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item  onClick={addCheckboxField}>
          Checkbox
        </Dropdown.Item>
        <Dropdown.Item  onClick={addDateField}>
          Date
        </Dropdown.Item>
        <Dropdown.Item  onClick={addEmailField}>
          Email
        </Dropdown.Item>
        <Dropdown.Item  onClick={addImageField}>
          Image
        </Dropdown.Item>
        <Dropdown.Item  onClick={addOptionField}>
          Options
        </Dropdown.Item>
        <Dropdown.Item onClick={addQuantityField}>
          Quantity
        </Dropdown.Item>
        <Dropdown.Item  onClick={addSelectField}>
          Select
        </Dropdown.Item>
        <Dropdown.Item  onClick={addTextField}>
          Text
        </Dropdown.Item>
        <Dropdown.Item  onClick={addTextareaField}>
          Textarea
        </Dropdown.Item>
        <Dropdown.Item  onClick={addVideoField}>
          Video
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AddItemOptions;
