export const API_ENDPOINT =
  "https://6xf4ffifnk.execute-api.eu-west-1.amazonaws.com/vyn-dev-poc";

//default values
export const DEFAULT_VIDEO_FORM_MODEL = {
  acceptMedia: "vyn.AcceptMediaTypes.AudioVideo",
  acceptMediaOptions: [
    "vyn.AcceptMediaTypes.AudioVideo",
    "vyn.AcceptMediaTypes.Video",
  ],
  canPause: false,
  maxDuration: 60000,
  width: 640,
  height: 480,
  facingMode: "vyn.FacingModes.Back",
  capture_label: "",
};
