import React from "react";
import Storyboard from "../storyboard/Storyboard.js";
import "./canvas.css";

const Canvas = (props) => {
  return (
    <>
      <div class="container-fluid">
        <div className="canvas" >
          <div className="box">
            <Storyboard data={props}></Storyboard>
          </div>
          <div id="clear" style={{ clear: "both" }}></div>
        </div>
      </div>
    </>
  );
};

export default Canvas;
