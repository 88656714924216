import logo from "./vynlogo.png";
import "./App.css";
import FormBuilderHeader from "./form-builder-components/form_builder_header/FormBuilderHeader";
import Canvas from "./form-builder-components/canvas/Canvas";
import { useEffect, useRef, useState } from "react";
import data from "./sample_jsons/default.json";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import DragUI from "./DragUI";
import ExportStoryboard from "./form-builder-components/export-storyboard/ExportStoryboard";
import { ExportOption } from "./form-builder-components/form_builder_header/header_components/Exportoptions";
import ErrorModal from "./generic_components/ErrorModal/ErrorModal";
import validateStoryboard from "./Utilities/StorboardValidator";
import slugify from "./Utilities/Slugify";
import { API_ENDPOINT } from "./Constants";
import ActivityIndicator from "./generic_components/ActivityIndicator/ActivityIndicator";

function App() {
  const showErrorModal = (erroMessage) => {
    var updatedErrorModalProp = { ...errorModalProps };
    updatedErrorModalProp.show = true;
    updatedErrorModalProp.message = erroMessage;
    setErrorModalProps(updatedErrorModalProp);
  };
  const hideErrorModal = () => {
    setErrorModalProps(defaultErrorModalProp);
  };
  const defaultErrorModalProp = {
    show: false,
    message: "",
    primaryButtonTitle: "OK",
    primaryAction: hideErrorModal,
  };

  const [errorModalProps, setErrorModalProps] = useState(defaultErrorModalProp);
  const [activityIndicatorProps, setActivityIndicatorProps] = useState({
    show: false,
    message: "",
  });
  const [formData, setFormData] = useState(data);
  const formDataRef = useRef(formData);

  const updateFormDataFromUI = (updatedFormData) => {
    setFormData(updatedFormData);
    formDataRef.current = updatedFormData;
  };

  const [showExportStoryboardModal, setShowExportStoryboardModal] =
    useState(false);

  const showStoryboardExport = () => {
    //console('<<<<<< app.js showStoryboardExport')
    setShowExportStoryboardModal(true);
  };

  const exportJson = (fileName) => {
    // let fileName = "json.txt"
    //console("exportJson " + fileName);
    let contentType = "text/plain";
    var a = document.createElement("a");
    var file = new Blob([JSON.stringify(formData, null, 2)], {
      type: contentType,
    });
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  };

  const hideStoryboardExport = (fileName) => {
    //console('<<<<<< app.js hideStoryboardExport')
    setShowExportStoryboardModal(false);
    if (fileName) {
      exportJson(fileName);
    }
  };

  const exportHtml = (fileName, htmlData) => {
    // let fileName = "json.txt"
    //console("exportJson " + fileName);

    let contentType = "text/plain";
    var a = document.createElement("a");
    var file = new Blob([htmlData], {
      type: contentType,
    });
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  };

  const showActivityIndicator = (message) => {
    setActivityIndicatorProps({ show: true, message: "  " + message });
  };

  const hideActivityIndicator = (message) => {
    setActivityIndicatorProps({ show: false, message: "" });
  };

  const createHtml = (fileName, fileType) => {
    console.log("call rest api");
    showActivityIndicator(fileType + " export in progress..");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "none");
    myHeaders.append("Content-Type", "application/json");
    // myHeaders.append('Access-Control-Allow-Origin', '*');

    var raw = JSON.stringify(formData);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    // let url  = 'https://jsonplaceholder.typicode.com/posts?_limit=10'
    let url = API_ENDPOINT + "?input=json&target=" + fileType;
    fetch(url, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        // console.log(JSON.parse(result))
        exportHtml(fileName, JSON.parse(result).template);
        hideActivityIndicator();
      })
      .catch((error) => {
        console.log("error", error);
        showErrorModal(error)
      });
  };

  const loadImportedJson = (newData) => {
    setFormData(newData);
    formDataRef.current = newData;
  };

  const checkIfStoryboardCreatiobInProgress = () => {
    return !(formDataRef.current === data);
  };
  //console('render with ' + showExportStoryboardModal)

  const handleExport = (exportType) => {
    let validationResult = validateStoryboard(formDataRef.current);
    if (validationResult) {
      showErrorModal(validationResult);
    } else {
      switch (exportType) {
        case ExportOption.JSON:
          console.log("<<<<< export JSON");
          exportJson(slugify(formDataRef.current.title) + ".json");
          break;
        case ExportOption.Jinja:
          console.log("<<<<< export Jinja");
          createHtml(
            slugify(formDataRef.current.title) + "_jinja" + ".html",
            "jinja"
          );
          break;
        case ExportOption.HTML:
          console.log("<<<<< export HTML");
          createHtml(slugify(formDataRef.current.title) + ".html", "html");
          break;
        default:
          showErrorModal("Unexpcted export type");
      }
    }
  };

  return (
    <>
     <div  className="wrapper">
    
     <ErrorModal errorModalProps={errorModalProps} />
      <FormBuilderHeader
        showStoryboardExport={showStoryboardExport}
        createHTML={createHtml}
        load={loadImportedJson}
        export={handleExport}
        checkIfStoryboardCreatiobInProgress={
          checkIfStoryboardCreatiobInProgress
        }
        onError={showErrorModal}
        hideError={hideErrorModal}
        showActiviy={showActivityIndicator}
        hideActivity={hideActivityIndicator}
      />
      {/* <DragUI></DragUI> */}
      <Canvas formData={formData} formOperation={updateFormDataFromUI} />
      {showExportStoryboardModal ? (
        <ExportStoryboard showModal={true} hideModel={hideStoryboardExport} />
      ) : (
        ""
      )}
      <ActivityIndicator indaicatorProps={activityIndicatorProps} />
      
         </div>
    
    </>
  );
}

export default App;

