import React from "react";
import ExpandingText from "../../expanding-text/ExpandingText";
import { FormFieldLevelOperation } from "../../form-field/FormField";
import slugify from "../../../Utilities/Slugify";

const ViewLabelFieldAttribute = (props) => {
  

  const handleChange = (value) => {
    // we have to make sure that slugified version of view label is updated in id as well
    //console(props.field);
    let currentSlugifiedViewLabel = slugify(props.field.view_label);
    let impactedAttributes = { view_label: value };
    if (
      props.field.id.length === 0 ||
      props.field.id === currentSlugifiedViewLabel
    ) {
      impactedAttributes["id"] = slugify(value);
    }

    props.operation(
      FormFieldLevelOperation.UpdateFieldAttribute,
      impactedAttributes
    );
  };
  return (
    <ExpandingText
      type="text"
      value={props.field.view_label}
      onChange={handleChange}
    ></ExpandingText>
  );
};

export default ViewLabelFieldAttribute;
