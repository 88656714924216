import React from "react";
import PropTypes from "prop-types";
import vyn_logo from "../../assets/vyn-logo.png";
import ImportOptions from "./header_components/ImportOptions";
import ExportOptions from "./header_components/Exportoptions";
const FormBuilderHeader = (props) => {
  const handleStoryboardExport = () => {
    props.showStoryboardExport();
  };
  return (
     <div class="container-fluid">
     <nav class="navbar navbar-expand-md navbar-dark bg-dark">
       <div class="container-fluid">		
          <a class="navbar-brand ms-2 ps-3" href="index.html">
            <img src={vyn_logo} width="80" height="40" alt="Vyn Logo"></img>
          </a>

          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main-nav">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse justify-content-end align-center" id="main-nav">
          <ul className="navbar-nav nav-tabs">
            <li class="nav-item m-2 ps-2">
              <a href="index.html"><span class="bi bi-house-door-fill m-2 p-1" style={{ color: "#9bddff" }}></span> <span style={{ color: "#9bddff" }}> Homepage</span></a>
            </li>
            <li class="nav-item">
              <ImportOptions
                load={props.load}
                checkIfStoryboardCreatiobInProgress={
                  props.checkIfStoryboardCreatiobInProgress
                }
                onError={props.onError}
                hideError={props.hideError}
                showActiviy={props.showActiviy}
                hideActivity={props.hideActivity}
             />
           </li>
           <li class="nav-item">
             <ExportOptions export={props.export} />
           </li>
        </ul>
      </div>
      </div>
    </nav>
    </div>
  );
};

FormBuilderHeader.propTypes = {};

export default FormBuilderHeader;
