import React, { useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { AllowedOperation, DependencyExpressionGenerator, DependencyModel } from "../../DependencyModel";
// import { AllowedOperation, DependencyModel } from "../../Dependency";

const DependencyId = (props) => {

  const handleIdSelection = (e) => {
    let currentDependency = { ...props.dependency };
    currentDependency.id = e;
    currentDependency.value = null
    props.update(currentDependency);
  };

  const getIdDropDown = () => {
    return (
      <>
        <DropdownButton
          id="dropdown-basic-button"
          title={props.dependency.id ? props.dependency.id : "--Select--"}
          onSelect={handleIdSelection}
          key={"d_id_dd" + Date.now()}
        >
          {Object.keys(props.form_ids_map).map((id, index) => {
            if (id !== props.formField.id){
              return (
                <Dropdown.Item key={"d_id_dd_item" + index} eventKey={id}>
                  {id}
                </Dropdown.Item>
              );
            }
          })}
        </DropdownButton>
        <div>{props.dependency.expression()}</div>
      </>
    );
  };
  return <div>{getIdDropDown()}</div>;
};

export default DependencyId;
