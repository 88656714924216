import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";

/**
 * This is modal component can have following elements
 * title
 * message
 * 2 configurable buttons
 *  - primary
 *  - secondary
 */
const SimpleModal = (props) => {
  const [showModal, setShowModal] = useState(props.modalProps.show);
  useEffect(()=>{
    setShowModal(props.modalProps.show)
  })

  return (
    <>
      <Modal show={showModal} onHide={props.modalProps.secondaryAction}>
        <Modal.Header closeButton>
          <Modal.Title>{props.modalProps.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.modalProps.message}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.modalProps.secondaryAction}>
            {props.modalProps.secondayButtonTitle}
          </Button>
          <Button variant="primary" onClick={props.modalProps.primaryAction}>
            {props.modalProps.primaryButtonTitle}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SimpleModal;
