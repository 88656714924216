import React from "react";
import MenuItems from "../../dropdown_menu/MenuItems";

export const ExportOption = Object.freeze({
    JSON : Symbol('JSON'),
    Jinja : Symbol('Jinja'),
    HTML : Symbol('HTML')
  })

/**
 * Export options on header
 * will have:
 * Json
 * Jinja
 * HTML
 */
const ExportOptions = (props) => {
  const handleJsonExport = () => {
    props.export(ExportOption.JSON)
  };
  const handleJinjaExport = () => {
    props.export(ExportOption.Jinja)
  };
  const handleHTMLExport = () => {
    props.export(ExportOption.HTML)
  };
  var defaultOptions = [
    { title: "JSON", onClickHandler: handleJsonExport },
    {
      title: "Jinja",
      onClickHandler: handleJinjaExport,
    },
    {
      title: "HTML",
      onClickHandler: handleHTMLExport,
    },
  ];
  const depthLevel = 0;
  return (
    <MenuItems
      title={"Save as"}
      options={defaultOptions}
      depthLevel={depthLevel}
    ></MenuItems>
  );
};

export default ExportOptions;
