import React, { useEffect, useRef, useState } from "react";
import "./MenuItems.css";
import Dropdown from "./Dropdown";

/**
 * multi level menu item with drop down support
 */
const MenuItems = (props) => {
  // console.log('<<<<< load menu item ' + props.title)
  const [dropdown, setDropdown] = useState(false);
  let ref = useRef();
  useEffect(() => {
    // console.log('<<<<<useEffect ' + dropdown + props.title)
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  const closeDropdown = () => {
    setDropdown(false)
    // console.log('close dropdown ' + dropdown)
    // dropdown && setDropdown(false);
    // console.log('close dropdown ' + dropdown + props.title)
  };

  return (

    <div className="menu-items" ref={ref}>
      {Array.isArray(props.options) && props.options.length > 0 ? (
        <>
        
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
            onClick={() => setDropdown((prev) => !prev)}
          >
            {props.depthLevel > 0 ? (
              <span></span>
            ) : (
              <span class="fa fa-upload mr-3"></span>
            )}
            {props.title}{"  "}
          </button>
          <Dropdown
            submenus={props.options}
            dropdown={dropdown}
            depthLevel={props.depthLevel}
            closeParent = {props.closeParent ? props.closeParent : closeDropdown}
          />
        </>
      ) : (
        <button
          onClick={() => {
            console.log(props)
            props.closeParent ? props.closeParent() : console.log('<<<<< must be parent ' + props.title)
            // closeDropdown()
            props.onClickHandler
              ? props.onClickHandler(props)
              : console.log("need to implement click handler");
          }}
        >
          {props.title}
        </button>
        // <a ></a>
      )}
    </div>

  );
};

export default MenuItems;