import React, { useEffect, useState } from "react";

import Carousel from "react-bootstrap/Carousel";
import ExpandingText from "../expanding-text/ExpandingText";
import FormPage from "../form-page/FormPage";
import "./Storyboard.css";
import vyn_storyboard_title from "../../assets/storyboard-screen.png";
import PageNavigator from "../page_navigator/PageNavigator";

/**
 * Operations at Storyboard level
 * - Delete Page
 * - Update page at Index
 */

export const StoryboardLevelOperations = Object.freeze({
  AddPage: Symbol("add_page"),
  DeletePage: Symbol("delete_page"),
  UpdatePage: Symbol("update_page"),
});

const Storyboard = (props) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const handlePageSelection = (selectedIndex, e) => {
    //console("handlePageSelection", selectedIndex);
    setCurrentPageIndex(selectedIndex);
  };

  const updateIdsMap = () => {
    let updatedMap = {};
    //console(props);
    props.data.formData.pages.map((page) => {
      page.sections.map((section) => {
        section.fields.map((field) => {
          let existingMap = updatedMap[field.id];
          let pageKey = page.id;
          let sectionKey = section.id;
          let completeKey =
            page.id + "::" + section.id + "::" + field.editor_id;
          if (existingMap) {
            existingMap.push(completeKey);
            updatedMap[field.id] = existingMap;
          } else {
            updatedMap[field.id] = [completeKey];
          }
        });
      });
    });
    return updatedMap;
  };

  useEffect(() => {
    updateIdsMap();
  }, []);

  const updatePagesInDataSource = (pages) => {
    let updatedFormData = { ...props.data.formData };
    updatedFormData.pages = pages;
    props.data.formOperation(updatedFormData);
    updateIdsMap();
  };

  const deletePage = (pageId) => {
    //console('delete pageId ' + pageId)
    // let pageId = props.page.id;
    let filterdPages = props.data.formData.pages.filter((e) => {
      return e.id !== pageId;
    });
    filterdPages.map((page, index) => {
      page.id = index + 1;
    });

    //console('delete pageId ' + pageId)
    //console('num pages ' + filterdPages.length)
    if (pageId > filterdPages.length) {
      setCurrentPageIndex(0);
    } else {
      setCurrentPageIndex(pageId - 1);
    }
    updatePagesInDataSource(filterdPages);
    // setCurrentPageIndex(0)
    //console('<<<<< move to ' + currentPageIndex)
  };

  const updatePage = (updatedPage) => {
    let updatedJson = { ...props.data.formData };
    let oldPageIndex = -1;
    updatedJson.pages.map((page, index) => {
      if (page.id === updatedPage.id) {
        oldPageIndex = index;
      }
    });
    updatedJson.pages.splice(oldPageIndex, 1, updatedPage);
    //console("updatePage " + oldPageIndex);
    updatePagesInDataSource(updatedJson.pages);
  };

  const operation = (opertaionType, params) => {
    switch (opertaionType) {
      case StoryboardLevelOperations.DeletePage:
        deletePage(params);
        break;
      case StoryboardLevelOperations.UpdatePage:
        updatePage(params);
        break;
      default:
        console.error("unidentified Storyboard Level Operations");
    }
  };

  const addPage = () => {
    //console(props.data.formData)
    let allPages = [...props.data.formData.pages];
    allPages.push({ id: allPages.length + 1, sections: [] });
    updatePagesInDataSource(allPages);
    setCurrentPageIndex(allPages.length - 1);
  };

  const handleStoryboardTitleChange = (value) => {
    let updatedFormData = { ...props.data.formData };
    updatedFormData.title = value;
    props.data.formOperation(updatedFormData);
  };

  const updatePages = (sortedPages) => {
    updatePagesInDataSource(sortedPages);
  };
  return (
    <>
      {props.data.formData.pages.length === 0 ? (
        <section id="hero" className="d-flex align-items-center m-2 p-2">
          <div class="container-fluid ">
            <div class="row text-start align-items-center">
              <div
                className="col-md-8 col-sm-6 d-flex flex-column text-start p-4 "
              >
                <h1>Vyn Storyboard Builder</h1>
                <h4 class="pb-4" style={{color: "#505050"}}>A simple tool to create Vyn storyboards</h4>
                <div className="d-flex justify-content-start">
                  <a onClick={addPage} className="btn-get-started scrollto">
                    New
                  </a>
                  <a
                    href="https://www.youtube.com/watch?v=sAFodTEC2vU"
                    className="glightbox btn-watch-video"
                  >
                    <i className="bi bi-play-circle"></i>
                    <span>Remote Assurance Video</span>
                  </a>
                  <a
                    href="https://www.youtube.com/watch?v=5Bz2nK_IAzs"
                    className="glightbox btn-watch-video"
                  >
                    <i className="bi bi-play-circle"></i>
                    <span>New Connections Video</span>
                  </a>
                  <a
                    href="https://www.youtube.com/watch?v=rOk58l8Ux7A"
                    className="glightbox btn-watch-video"
                  >
                    <i className="bi bi-play-circle"></i>
                    <span>Reducing Downtime Video</span>
                  </a>
                </div>
              </div>
              <div
                className="col-md-4 hero-img text-center d-none d-md-block pt-4 pb-4"
              >
                <img
                  src={vyn_storyboard_title}
                  className="img-fluid"
                  alt=""
                ></img>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div class="container-fluid ">
          <div class="row text-start align-items-center m-2 p-2">
            <div className="col-md-8 text-center text-md-start pt-4">
              <h5>Storyboard Title</h5>

              <div style={{ flex: 1 }}>
                <ExpandingText
                  value={props.data.formData.title}
                  onChange={handleStoryboardTitleChange}
                  placeholder={"Enter a title for the Storyboard"}
                  style={{ width: "100%"}}
                ></ExpandingText>
                {/* <input type="text" value={props.data.formData.title} onChange={handleStoryboardTitleChange}></input> */}
              </div>
            </div>
            <div class="col-md-4 text-end">
              <a href="#info-sidebar" class="info-link d-block m-2" data-bs-toggle="offcanvas" role="button">
                <i style={{color: "#93AE1D"}} class="bi bi-info-circle-fill h4"></i> 
              </a>
              <button className="btn-sm btn-secondary mr-2" onClick={addPage}><i class="bi bi-file-earmark-plus"></i> 
                &nbsp; Add Page
              </button>
            </div>
          </div>
          <div className="storyboard">
            <PageNavigator
              id="page_navigator"
              data={props.data.formData}
              updatePages={updatePages}
              handlePageSelection={handlePageSelection}
            ></PageNavigator>
            <Carousel
              interval={null}
              activeIndex={currentPageIndex}
              onSelect={handlePageSelection}
              controls={false}
            >
              {props.data.formData.pages.map((page, index) => {
                return (
                  <Carousel.Item key={index} style={{ paddingBottom: 50 }}>
                    {
                      <FormPage
                        data={props.data.formData}
                        page={page}
                        index={index}
                        key={index}
                        operation={operation}
                        form_ids_map={updateIdsMap()}
                      ></FormPage>
                    }
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </div>

          <div class="offcanvas offcanvas-end" data-bs-scroll="true" tabindex="-1" id="info-sidebar">
            <div class="offcanvas-header">
              <h5 class="offcanvas-title">Storyboards</h5>
              <button type="button" class="btn-close" data-bs-dismiss="offcanvas"></button>
            </div>
            <div class="offcanvas-body">
              <h5>Vyn </h5>
              <h6> Short structured video notes. </h6>
              <p> A Vyn is the primary component within the Vyn platform. A Vyn is captured by a user in response to a workflow event, analysed by the Vyn platform and then published back into your workflow. </p>

              <h5> Storyboard </h5>
              <h6> Set of questions, typically structured as one or more dynamic pages. </h6>

              <p> Storyboards guide the user to capture the key elements of a video note. They can be created and customised according to the requirements of your workflow. Storyboards ensure relevant data is captured and drive consistency. </p>

              <h5> Section </h5>
              <h6> Pages are broken into sections. </h6>
              <p> A section of a storyboard that focuses on a specific theme or a topic. Each section can capture video, images and optional quantitive elements. </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Storyboard;
