import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";

const ActivityIndicator = (props) => {
  const [show, setShow] = useState(props.indaicatorProps.show);
  useEffect(() => {
    setShow(props.indaicatorProps.show);
  });
  return (
    <div
      className="modal show"
      style={{position: "initial" }}
    >
      <Modal show={show}>
        {/* <Modal.Header></Modal.Header> */}
        <Modal.Body>
          <Button variant="primary" disabled>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            {props.indaicatorProps.message}
          </Button>
        </Modal.Body>
        {/* <Modal.Footer></Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default ActivityIndicator;
