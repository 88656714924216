import React from "react";
import "./ReqiredFieldAttribute.css";
import { FormFieldLevelOperation } from "../../form-field/FormField";


const ReqiredFieldAttribute = (props) => {
 
  const formModel =  props.formField.form_model
  const handleIsRequiredCheckboxChange = (event) => {
    var updatedFormModel = {...props.formField.form_model}
    if (!event.target.checked){
      updatedFormModel.required = false
    }else{
      delete updatedFormModel.required
    }
     
    //console(updatedFormModel)
    props.operation(
        FormFieldLevelOperation.UpdateFieldAttribute,
        {'form_model' : updatedFormModel}
      );
  };

  return (
    <>
      <div
        key={props.formField.editor_id}
        className="form-field-attribute-container"
      >
        <div>Required</div>
        <div>
          <input
            type="checkbox"
            checked={formModel.required == false ? formModel.required : true}
            onChange={handleIsRequiredCheckboxChange}
          />
        </div>
      </div>
    </>
  );
};

export default ReqiredFieldAttribute;
