import React from "react";
import "./MenuItems.css";
import MenuItems from "./MenuItems";

/**
 * Drop down component in for a multi level menu item
 */
const Dropdown = (props) => {
  let depthLevel = props.depthLevel + 1;
  const dropdownClass = depthLevel > 1 ? "customDropdown-submenu" : "";
  return (
    <ul
      className={`customDropdown ${dropdownClass} ${
        props.dropdown ? "show" : ""
      }`}
    >
      {props.submenus.map((submenu, index) => (
        <MenuItems
          title={submenu.title}
          template_type = {submenu.type}
          template_id = {submenu.id}
          // updatedTemplate['id'] = template['id']
          options={submenu.options}
          onClickHandler={submenu.onClickHandler}
          key={index}
          depthLevel={depthLevel}
          closeParent = {props.closeParent}
        />
      ))}
    </ul>
  );
};

export default Dropdown;