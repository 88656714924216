import React, { useCallback, useEffect, useRef, useState } from "react";
import Textarea from "react-expanding-textarea";

const ExpandingText = (props) => {
  const textareaRef = useRef(null);
  const [fieldValue, setFieldValue] = useState(props.value)
  const handleChange = useCallback((e) => {
    console.log("Changed value to: " + e.target.value + ' key = ' + props.field_key);
    setFieldValue(e.target.value)
  }, []);

  useEffect(()=>{
    props.onChange(fieldValue, props.field_key)
  }, [fieldValue])

  useEffect(() => {
    textareaRef.current.focus();
  }, []);
  return (
    <Textarea
      defaultValue={props.value}
      // key={props.key}
      id={props.defaultValue}
      onChange={handleChange}
      placeholder={props.placeholder}
      ref={textareaRef}
      style={{width: '100%', padding: "3px 10px"}}
    ></Textarea>
  );
};

export default ExpandingText;
