import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
/**
 * Modal to display error
 */
const ErrorModal = (props) => {
  const [showModal, setShowModal] = useState(props.errorModalProps.show);
  useEffect(() => {
    setShowModal(props.errorModalProps.show);
  });

  return (
    <>
      <Modal show={showModal} onHide={props.errorModalProps.primaryAction}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.errorModalProps.message}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={props.errorModalProps.primaryAction}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ErrorModal;
