import React from "react";
import ExpandingText from "../../expanding-text/ExpandingText";
import { FormFieldLevelOperation } from "../../form-field/FormField";

const CaptureLabelFieldAttribute = (props) => {
  const handleChange = (value) => {
    /**
     * we have to make sure that capture label of form model get updated
     * in case of video we have a capture_label in form_model
     */
    let impactedAttributes = { capture_label: value };
    if (props.field.form_model.hasOwnProperty('videoCapture')){
        let videoCaptureLabel = props.field.form_model.videoCapture.capture_label;
        if (videoCaptureLabel === props.field.capture_label) {
            var updatedFormModel = {...props.field.form_model}
            updatedFormModel.videoCapture.capture_label = value
            impactedAttributes.form_model = updatedFormModel
        }
    }
    

    props.operation(
      FormFieldLevelOperation.UpdateFieldAttribute,
      impactedAttributes
    );
  };
  return (
    <ExpandingText
      type="text"
      value={props.field.capture_label}
      onChange={handleChange}
    ></ExpandingText>
  );
};

export default CaptureLabelFieldAttribute;
