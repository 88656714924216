import React from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";

/**
 * Component to select color for options field attribute
 */
const OptionColorDropDown = (props) => {
  const availableColors = { 
    "vsf-green vsf-green-border" : 'GREEN',
    "vsf-red vsf-red-border" : 'RED' ,
    "vsf-orange vsf-orange-border" : 'ORANGE' ,
  };
  const handleChange = (val) => {
    // 
    console.log(val)
    props.handleColorSelection(val)
  };

  return (
    <DropdownButton
      id="dropdown-basic-button"
      title={availableColors[props.currentColor]}
      onSelect={handleChange}
      key={"dependency_operator_dd" + Date.now()}
    >
      {Object.keys(availableColors).map((id, index) => {
        return (
          <Dropdown.Item
            key={"dependency_option_color_dd_item" + index}
            eventKey={id}
          >
            {availableColors[id]}
          </Dropdown.Item>
        );
      })}
    </DropdownButton>
  );
};

export default OptionColorDropDown;
