import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import "./PageNavigator.css";

export function SortablePageItem(props) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handlePageSelection = () =>{
    console.log( '<<<<<<<< handlePageSelection' + props.currentIndex)
    props.handlePageSelection(props.currentIndex)
  }
  return (
    <button
      className="page-navigator-item"
      ref={setNodeRef}
      style={style}
      onClick={handlePageSelection}
      {...attributes}
      {...listeners}
      
    >
      {props.id}
    </button>
  );
}

export default SortablePageItem;
