import React, { useEffect, useState } from "react";
import "./PageNavigator.css";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import SortablePageItem from "./SortablePageItem";
/**
 * This is the page navigator
 * we'll use this to for following purpose
 * - navigate between the pages
 * - reorder the pages
 */
const PageNavigator = (props) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
        activationConstraint:{
            delay: 150
        }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  console.log(props.data);
  const [items, setItems] = useState(props.data.pages);

  useEffect(() => {
    setItems(props.data.pages);
  }, [props]);

  function updateDatasourceAfterSectionDragEnd(event) {
    console.log("dragged");
    let items = props.data.pages;
    var newIndex = -1
    const { active, over } = event;
    if (active.id !== over.id) {
      let activeSection = items.filter(
        (section) => section.id === active.id
      )[0];
      let overSection = items.filter((section) => section.id === over.id)[0];
      const oldIndex = items.indexOf(activeSection);
      newIndex = items.indexOf(overSection);
      items = arrayMove(items, oldIndex, newIndex);
    }
    props.updatePages(items);
    props.handlePageSelection(newIndex)
  }
  return (
    <div className="page-navigator">
      <div className="page-navigator-header">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={updateDatasourceAfterSectionDragEnd}
        >
          <SortableContext
            items={items}
            strategy={horizontalListSortingStrategy}
          >
            {items.map((item, index) => (
              <SortablePageItem
                key={item.id}
                id={item.id}
                currentIndex = {index}
                handlePageSelection={props.handlePageSelection}
              />
            ))}
          </SortableContext>
        </DndContext>
      </div>
    </div>
  );
};

export default PageNavigator;
