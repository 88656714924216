import React, { useEffect, useState } from "react";
import MenuItems from "../../dropdown_menu/MenuItems";
import "../../dropdown_menu/MenuItems.css";
import { API_ENDPOINT } from "../../../Constants";
import defaultStoryboardData from "../../../sample_jsons/default.json";
import SimpleModal from "../../../generic_components/SimpleModal/SimpleModal";
/**
 * This component adds a menu item in header and shows various options to import a storyboard or can initiate a new
 * storyboard creation
 * User can import storyboard from:
 * - Local files
 * - templates
 *
 * Templates will be fetched via an api
 */
export default function ImportOptions(props) {
  const handleLoadStorboardFromLocalFile = () => {
    console.log("load file");
    document.getElementById("file-input").click();
  };



  const fetchTemplate = (params) => {
    props.showActiviy('Fetching template ' + params.title)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "none");
    myHeaders.append("Content-Type", "application/json");
    var input_data = {
      type: params.template_type,
      id: params.template_id,
    };
    var raw = JSON.stringify(input_data);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };
    let url = API_ENDPOINT + "?input=one&target=template";
    fetch(url, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        props.hideActivity()
        console.log("<<<<<<<  fetched Template");
        let templateFetchResult = JSON.parse(result);
        console.log(templateFetchResult);
        if ("template" in templateFetchResult) {
          props.load(templateFetchResult["template"]);
        }else{
          props.onError('Unable to fetch the template.')
        }
      })
      .catch((error) => {
        console.log("error", error)
        props.onError(error)
      });
  };

  const handleSelectedTemplate = (params) => {
    if (props.checkIfStoryboardCreatiobInProgress()){
      var updatedModalProps = {...defaultModalProp}
      updatedModalProps.show = true
      updatedModalProps.message = 'You will loose your existing storyboard and a new one will be loaded using selected template.'
      updatedModalProps.primaryAction = ()=>{
        updatedModalProps.show = false
        setModalProps(updatedModalProps);
        fetchTemplate(params)
      }
      setModalProps(updatedModalProps);
    }else{
      fetchTemplate(params)
    }
  }

  const cancelNewStoryboard = () => {
    setModalProps({ show: false });
  };

  const createNewStoryboard = () => {
    setModalProps({ show: false });
    props.load(defaultStoryboardData)
  };

  const defaultModalProp = {
    'show': false,
    'title': 'Warning',
    'message': 'This will create a blank storyboard.',
    'secondayButtonTitle': 'No',
    'primaryButtonTitle': 'Yes',
    'primaryAction': createNewStoryboard,
    'secondaryAction': cancelNewStoryboard,
  };
  const [modalProps, setModalProps] = useState(defaultModalProp);
  const openNewStoryboard = () => {
    /**
     * Before clearing show a warning  This will create a blank storyboard yes/No?
     */
    if (props.checkIfStoryboardCreatiobInProgress()){
      var updatedModalProps = {...defaultModalProp}
      updatedModalProps.show = true
      setModalProps(updatedModalProps);
    }else{
      props.load(defaultStoryboardData)
    }
  };

  var defaultOptions = [
    { title: "New", onClickHandler: openNewStoryboard },
    {
      title: "Upload",
      onClickHandler: handleLoadStorboardFromLocalFile,
    },
    {
      title: "Template",
      options: [],
    },
  ];
  const [options, setOptions] = useState(defaultOptions);

  /**
   * this will be used to fetch the templates
   */
  useEffect(() => {
    // console.log("<< fetch templates");
    // props.showActiviy('Fetching templates')
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "none");
    myHeaders.append("Content-Type", "application/json");
    var input_data = {
      input_data: {
        type: "Safety",
        id: "30_sec_safety",
      },
    };
    var raw = JSON.stringify(input_data);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };
    let url = API_ENDPOINT + "?input=list&target=template";
    fetch(url, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        // props.hideActivity()
        // console.log('<<<<<<< Templates')
        let templateFetchResult = JSON.parse(result);
        if ("template" in templateFetchResult) {
          //read templates from received response
          let allTemplates = templateFetchResult["template"];
          var updatedTemplateOptions = [];
          /**
           * covert emplates to the drop down menu format
           */
          Object.keys(allTemplates).map((key) => {
            let templateCategory = { title: key };
            let rawTemplates = allTemplates[key];
            var templates = [];
            if (Array.isArray(rawTemplates)) {
              rawTemplates.map((template) => {
                var updatedTemplate = { ...template };
                updatedTemplate["title"] = template["view_label"];
                updatedTemplate["type"] = key;
                updatedTemplate["id"] = template["id"];
                updatedTemplate["onClickHandler"] = handleSelectedTemplate;
                templates.push(updatedTemplate);
              });
            }
            templateCategory["options"] = templates;
            updatedTemplateOptions.push(templateCategory);
          });
          var updatedTemplates = {
            title: "Template",
            options: updatedTemplateOptions,
          };
          var updatedOptions = [];
          options.map((option) => {
            if (option.title === "Template") {
              updatedOptions.push(updatedTemplates);
            } else {
              updatedOptions.push(option);
            }
          });
          /**
           * update option so that it can be loaded in menu options
           */
          setOptions(updatedOptions);
        }
      })
      .catch((error) =>{ 
        console.log("error", error)
        props.onError(error)
      });
  }, []);

  const depthLevel = 0;

  let fileReader;

  const handleFileRead = (e) => {
    const content = fileReader.result;
    console.log(JSON.parse(content));
    props.load(JSON.parse(content));
  };
  const handleChosenJsonFile = (file) => {
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(file);
  };
  /**
   * we have a hidden file input field here
   */
  return (
    <>
      <SimpleModal modalProps={modalProps} />
      <input
        id="file-input"
        type="file"
        name="name"
        style={{ display: "none" }}
        onChange={(e) => handleChosenJsonFile(e.target.files[0])}
      />
      <MenuItems
        title={"Load"}
        options={options}
        depthLevel={depthLevel}
      ></MenuItems>
    </>
  );
}
